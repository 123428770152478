import React, { useState } from 'react'
import './index.scss'
import { Plus, Minus } from 'react-feather'

const Faq = () => {
  let questions = [
    {
      question: 'Quem pode ter o ReservaX Prime?',
      answer: 'Holders do colecionável Pistol Bird categoria Admission têm acesso ao Prime.',
    },
    {
      question: 'Há diferença do Prime dos holders para o de quem compra no site?',
      answer:
        'Não há diferença. Por enquanto, a única coisa que muda é o método de acesso em versão beta. Os holders têm ainda a vantagem de não pagarem nada, enquanto assinantes comuns pagam 12x de R$ 39.',
    },
    {
      question: 'Como resgatar meu acesso ao Prime?',
      answer:
        'Basta inscrever-se no formulário do início desta página. Assim, nossa equipe fará sua integração ao sistema do Prime, o que leva até 72 horas.',
    },
    {
      question: 'O que é a versão beta e o que é token-gated?',
      answer:
        'A versão beta é nosso recurso temporário de autenticação dos holders, para permitir que acessem e aproveitem os benefícios do Prime. Token gated é o mecanismo utilizado para validar a permissão de acesso desses holders.',
    },
    {
      question: 'Enquanto eu for holder de um NFT Pistol Bird, serei cliente Prime?',
      answer:
        'Sim. Posteriormente, a forma de acesso será definitiva e automatizada, permitindo um acesso contínuo e ainda mais fácil ao Prime.',
    },
    {
      question: 'Ao ir nas lojas, meu benefício Prime como holder será reconhecido?',
      answer:
        'Assim que resgatar seu acesso ao Prime, bastará informar seu CPF nas lojas para aproveitar os descontos na sua compra.',
    },
  ]
  const [select, setSelect] = useState(null)
  return (
    <div className='faq' id='faq'>
      <div className='glow' />
      <div className='faq__left'>
        <header>Perguntas Frequentes</header>
        <div>
          É holder e tá com dúvidas? <span>Deixa que a gente explica.</span>
        </div>
      </div>
      <div className='faq__right'>
        {questions.map((item, idx) => {
          return (
            <div
              className='faq__item'
              onClick={() => (select === idx ? setSelect(null) : setSelect(idx))}>
              <div className='faq__item__question'>
                {item.question}
                {idx === select && <div className='faq__item__answer'>{item.answer}</div>}
              </div>
              {idx === select ? (
                <Minus size={30} color='white' />
              ) : (
                <Plus size={30} color='white' />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Faq
