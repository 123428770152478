import React from 'react'
import './index.scss'
import alert from '../../../../assets/icons/alert.png'

const Alert = () => {
  return (
    <div className='alert'>
      <div className='glow' />
      <img src={alert} alt='alert' />
      <div>
        <img src={alert} alt='alert' />
        Por enquanto, o acesso acontece no formato beta, com verificações mensais.{' '}
        <span>Holders terão suas wallets conferidas por meio de um snapshot mensal,</span> executado
        em processo token-gated. A checagem será mantida até a integração completa entre ReservaX e
        Prime.
      </div>
    </div>
  )
}

export default Alert
