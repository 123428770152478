import React from 'react'
import reservaPrimeLogo from '../../../../assets/icons/reservaPrimeLogo.png'
import opensea from '../../../../assets/icons/opensea.png'
import './index.scss'
import Button from '../../../../components/Button'

const Header = () => {
  return (
    <div className='header'>
      <img src={reservaPrimeLogo} alt='Logo da Reserva Prime' />
      <div className='header__right'>
        <span onClick={() => document.querySelector('#about').scrollIntoView()}>Sobre</span>
        <span onClick={() => document.querySelector('#faq').scrollIntoView()}>FAQ</span>
        <a href='https://x.usereserva.com/area-do-holder' target='_blank' rel='noreferrer'>
          <Button text='área do holder' />
        </a>
        <a href='https://opensea.io/ReservaX/created' target='_blank' rel='noreferrer'>
          <img src={opensea} alt='Opensea icon' />
        </a>
      </div>
    </div>
  )
}

export default Header
