export default function app(state = {
  user: {},
  snackbar: '',
  undo: {
    action: '',
    data: {},
  }
}, action) {
  switch (action.type) {
    case 'CHANGE_USER':
      return { ...state, user: action.payload }
    case 'CHANGE_SNACKBAR':
      return { ...state, snackbar: action.payload }
    case 'CHANGE_CRM_UNDO':
      return { ...state, undo: action.payload }
    default:
      return state
  }
}
