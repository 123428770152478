import React from 'react'
import './index.scss'
import Button from '../../../../components/Button'
const MoreInfo = () => {
  return (
    <div className='more-info'>
      <header>Quer conhecer mais sobre o Prime?</header>
      <p>
        Aproveite todos os benefícios que os clientes normais têm. A diferença é que você não vai
        pagar por isso.
      </p>
      <div className='more-info__button'>
        <Button text='Resgatar' onClick={() => document.querySelector('#prime').scrollIntoView()} />
      </div>
    </div>
  )
}

export default MoreInfo
