import React from 'react'
import './index.scss'
import reservaPrimeLogo from '../../../../assets/icons/reservaPrimeLogo.png'
import twitter from '../../../../assets/icons/twitter.svg'
import instagram from '../../../../assets/icons/instagram.svg'
import discord from '../../../../assets/icons/discord.svg'
import opensea from '../../../../assets/icons/opensea.svg'
import threads from '../../../../assets/icons/threads.svg'

const Footer = () => {
  return (
    <>
      <div className='footer__background' />
      <div className='footer'>
        <div className='footer__main'>
          <img src={reservaPrimeLogo} alt='logo' />
          <h1>
            ReservaX é a divisão da Reserva, dedicada a aprender, organizar e implementar
            oportunidades do ecossistema Web3.
          </h1>
          <div className='footer__container'>
            <div className='footer__pages'>
              <a
                href='https://x.usereserva.com/politica-de-privacidade'
                target='_blank'
                rel='noreferrer'>
                Política de Privacidade
              </a>
              <span />
              <a
                href='https://x.usereserva.com/termos-e-condicoes'
                target='_blank'
                rel='noreferrer'>
                Termos e Condições
              </a>
              <span />
              <a href='https://x.usereserva.com/fale-conosco' target='_blank' rel='noreferrer'>
                Fale Conosco
              </a>
              <span />
            </div>
            <div className='footer__media'>
              <a href='https://twitter.com/usereserva' target='_blank' rel='noreferrer'>
                <img src={twitter} alt='twitter' />
              </a>
              <a href='https://www.instagram.com/usereservax/' target='_blank' rel='noreferrer'>
                <img src={instagram} alt='instagram' />
              </a>
              <a href='https://discord.com/invite/reservax' target='_blank' rel='noreferrer'>
                <img src={discord} alt='discord' />
              </a>
              <a href='https://opensea.io/ReservaX/created' target='_blank' rel='noreferrer'>
                <img src={opensea} alt='opensea' />
              </a>
              <a href='https://www.threads.net/@usereservax' target='_blank' rel='noreferrer'>
                <img src={threads} alt='threads' />
              </a>
            </div>
          </div>
        </div>
        <div className='footer__address'>
          Arezzo Industria e Comercio S.A. | 16.590.234/0114-53 | Rua Conde de Leopoldina, 686 -
          Vasco da Gama - Rio de Janeiro - RJ | CEP: 20930-460
        </div>
        <footer>
          O NFT Pistol Bird não se trata de oferta de valores mobiliários ou investimento coletivo.
          A presente oferta de compra não se trata de recomendação de investimentoe não foi
          concebida para prover lucro nem qualquer tipo de retorno financeiro e sim, tão e somente,
          o acesso ao clube de vantagens do ReservaX.
        </footer>
        <div className='footer__rights'>© 2023 GoX | ReservaX Ltda. All rights reserved.</div>
      </div>
    </>
  )
}

export default Footer
