import React, { useState } from 'react'
import prime from '../../../../assets/icons/prime.png'
import './index.scss'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import tag from '../../../../assets/icons/tag.png'
import diamond from '../../../../assets/icons/diamond.png'
import box from '../../../../assets/icons/box.png'
import Header from '../Header'
import ABI from '../../../../assets/contract/abi.json'
import { ToastContainer, toast } from 'react-toastify'
import { PulseLoader } from 'react-spinners'
import 'react-toastify/dist/ReactToastify.css'
import { cpf as cpfMask, validateEmail, validateCpf } from '../../../../constants/validate'
import { default as Web3 } from 'web3'
import axios from 'axios'
import Alert from '../Alert'

const Hero = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')
  const [loading, setLoading] = useState(false)

  async function sendForm() {
    const { ethereum } = window
    if (ethereum) {
      setLoading(true)

      if (name.length === 0 || email.length === 0 || cpf.length === 0) {
        toast.error('Preencha todos os campos')
        setLoading(false)
        return
      }

      if (!validateEmail(email)) {
        toast.error('Email inválido')
        setLoading(false)
        return
      }

      if (!validateCpf(cpf)) {
        toast.error('CPF inválido')
        setLoading(false)
        return
      }

      // const web3 = new Web3(ethereum)
      // const contract = new web3.eth.Contract(ABI, '0x15526af86721e8265139e4f3d759c343ac1d53ce')

      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })

      axios
        .post('https://reservax.55unity.com/users/prime', {
          name,
          email,
          cpf,
          account: accounts[0],
        })
        .then(response => {
          toast.success('Resgatado com sucesso!')
          setName('')
          setEmail('')
          setCpf('')
        })
        .catch(e => toast.error('Erro ao enviar formulário. Tente novamente mais tarde'))
      // let pistolAccount = null
      // let balance = 0
      // accounts.push('0x7eDA755A7D4C25705f0B94E5efb755563e717Edc')
      // for (let i = 0; i < accounts.length; i++) {
      //   const array = Array(6).fill(accounts[i])
      //   const tokens = [1, 2, 3, 4, 5, 6]

      //   const balances = await contract.methods.balanceOfBatch(array, tokens).call()

      //   balances.map(item => (balance += Number(item)))
      //   if (balance > 0) {
      //     pistolAccount = accounts[i]
      //   }
      //   balance = 0
      // }

      // if (!pistolAccount) {
      //   toast.error('Sua carteira não possui um Pistol Bird')
      // } else {

      // }
      setLoading(false)
    } else {
      window.open(`https://metamask.app.link/dapp/${window.location.href}`)
    }
  }

  return (
    <>
      <div className='hero__background' />
      <ToastContainer theme='dark' />
      <div className='overlay' />
      <Header />
      <div className='hero' id='prime'>
        <img src={prime} alt='Prime Logo' />
        <div className='hero__title'>
          Holder, chegou sua vez. Resgate por <span>12x deR$39</span>
          <span> gratuitamente</span> seu acesso ao clube de benefícios da Reserva.
        </div>
        <div className='hero__inputs'>
          <Input placeholder='Nome completo' title='Nome' value={name} onChange={setName} />
          <Input
            placeholder='E-mail'
            title='E-mail'
            type='email'
            value={email}
            onChange={setEmail}
          />
          <Input placeholder='CPF' title='CPF' value={cpf} onChange={setCpf} mask={cpfMask} />
        </div>
        <div className='hero__button'>
          <Button
            text={loading ? <PulseLoader color='white' size={5} /> : 'Resgatar'}
            onClick={() => sendForm()}
          />
        </div>
        <Alert />
        <div className='hero__advantages' id='about'>
          <div className='glow' />
          <h1>Quais as vantagens de ser Prime?</h1>
          <p>
            Você tem direito a um infinito de comodidades pra aproveitar no site, app, lojas e até
            em marcas parceiras. Além de acesso a pré-lançamentos e produtos exclusivos, você também
            garante:
          </p>
          <div className='hero__advantages__itens'>
            <div className='hero__advantages__item'>
              <img src={tag} alt='tag' />
              <div className='hero__advantages__item__title'>Descontos especiais</div>
              <div className='hero__advantages__item__description'>
                <span>20% OFF*</span> em compras a partir de R$ 499 em todas as peças das marcas
                Reserva.
              </div>
            </div>
            <div className='hero__advantages__item'>
              <img src={box} alt='box' />
              <div className='hero__advantages__item__title'>Frete Grátis</div>
              <div className='hero__advantages__item__description'>
                <span>Em toda compra*</span>, sem valor mínimo e válido em todo o Brasil.
              </div>
            </div>
            <div className='hero__advantages__item'>
              <img src={diamond} alt='diamond' />
              <div className='hero__advantages__item__title'>Clube de Vantagens</div>
              <div className='hero__advantages__item__description'>
                <span>Acumule mais*</span> pra comprar o que você quiser, quando preferir.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
