import React from 'react'
import './index.scss'

const Input = ({ title, type = 'text', value, onChange, placeholder, mask }) => {
  function handleChange(value) {
    if (mask) {
      return mask(value)
    } else {
      return value
    }
  }
  return (
    <div className='input'>
      {title}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange && onChange(handleChange(e.target.value))}
      />
    </div>
  )
}

export default Input
