import React from 'react'
import './index.scss'
import shirt from '../../../../assets/icons/shirt.png'
import Button from '../../../../components/Button'

const Sign = () => {
  return (
    <div className='sign'>
      <div className='sign__footer__mobile'>
        <span>
          <Button
            text='Resgatar'
            onClick={() => document.querySelector('#prime').scrollIntoView()}
          />
        </span>
        {/* <footer>
          *Exceto para parcerias, assinaturas, livros, drinks e máscaras. Desconto não cumulativo
          com outras promoções e cupons.
        </footer> */}
      </div>
      <img src={shirt} alt='shirt' />
      <div className='sign__info'>
        <header>Economize na Reserva</header>
        <p>
          Você paga menos nos seus pedidos de todas as nossas marcas. Enquanto você for holder,
          poderá aproveitar os benefícios do Prime.
        </p>
        <div className='sign__footer__desktop'>
          <span>
            <Button
              text='Resgatar'
              onClick={() => document.querySelector('#prime').scrollIntoView()}
            />
          </span>
          {/* <footer>
            *Exceto para parcerias, assinaturas, livros, drinks e máscaras. Desconto não cumulativo
            com outras promoções e cupons.
          </footer> */}
        </div>
      </div>
    </div>
  )
}

export default Sign
