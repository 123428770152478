import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

// import env from "./env";
import reducers from "./reducers/reducers";

// if (env === 'production') {
//   axios.defaults.baseURL = 'https://server.falejur.com.br'
// } else {
//   axios.defaults.baseURL = 'http://127.0.0.1:3001'
// }

// Logout when unauthorized
let user = localStorage.getItem("user");
if (typeof user === "string") {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem("user");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
}

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
