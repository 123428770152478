import React from 'react'
import './index.scss'
import Hero from './components/Hero'
import Sign from './components/Sign'
import Alert from './components/Alert'
import Faq from './components/Faq'
import MoreInfo from './components/MoreInfo'
import Footer from './components/Footer'

const Home = () => {
  return (
    <div className='home'>
      <div className='home__container'>
        <Hero />
        <Sign />
        {/* <Alert /> */}
        <Faq />
        <MoreInfo />
        <Footer />
      </div>
    </div>
  )
}

export default Home
