export const required = value => (value ? undefined : 'Campo Obrigatório')

export const selected = value => (value ? undefined : 'Opção Inexistente')

export const minLength = min => value =>
  value && value.length < min ? `Campo Incompleto` : undefined

export const minLength6 = minLength(6)

export const minLength13 = minLength(13)

export const minLength14 = minLength(14)

export const minLength18 = minLength(18)

export const minLength25 = minLength(25)

export const email = value =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? 'Email Inválido' : true

export const validateEmail = (value = '') =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? true : false

export const validatePassword = (password = '') =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/gm.test(password) ? true : false

export const validateOab = value => (value.length !== 9 ? `Formato incorreto. Ex: 123123/RJ` : true)

export const onlyNumber = value => {
  return String(value).replace(/[^\d]/g, '')
}

export const formatDocument = value => {
  if (onlyNumber(value).length <= 11) {
    value = cpf(String(value))
  } else if (onlyNumber(value).length >= 12) {
    value = cnpj(String(value).slice(0, 14))
  }
  return value
}

export const cpf = value => {
  const cpfOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (value.length <= 3) {
    return `${cpfOnlyNumber}`
  }
  if (value.length <= 6) {
    return `${cpfOnlyNumber.slice(0, 3)}.${cpfOnlyNumber.slice(3, 6)}`
  }
  if (value.length <= 9) {
    return `${cpfOnlyNumber.slice(0, 3)}.${cpfOnlyNumber.slice(3, 6)}.${cpfOnlyNumber.slice(6, 9)}`
  } else {
    return `${cpfOnlyNumber.slice(0, 3)}.${cpfOnlyNumber.slice(3, 6)}.${cpfOnlyNumber.slice(
      6,
      9
    )}-${cpfOnlyNumber.slice(9, 11)}`
  }
}

export const cnpj = value => {
  const cnpjOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (value.length <= 2) {
    return `${cnpjOnlyNumber.slice(0, 2)}`
  }
  if (value.length <= 5) {
    return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}`
  }
  if (value.length <= 8) {
    return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}.${cnpjOnlyNumber.slice(
      5,
      8
    )}`
  }
  if (value.length <= 12) {
    return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}.${cnpjOnlyNumber.slice(
      5,
      8
    )}/${cnpjOnlyNumber.slice(8, 12)}`
  }
  if (value.length <= 14) {
    return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}.${cnpjOnlyNumber.slice(
      5,
      8
    )}/${cnpjOnlyNumber.slice(8, 12)}-${cnpjOnlyNumber.slice(12, 14)}`
  }
}

export const unformatDocument = value => {
  return (value = value.replace(/(\.|\/|)/g, ''))
}

export const expiredAt = value => {
  const expiredAtOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (value.length <= 2) {
    return `${expiredAtOnlyNumber}`
  } else {
    return `${expiredAtOnlyNumber.slice(0, 2)}/${expiredAtOnlyNumber.slice(2, 4)}`
  }
}

export const creditCard = value => {
  const creditCardOnlyNumber = onlyNumber(value)
  if (!value) {
    return value
  }
  if (value.length <= 4) {
    return `${creditCardOnlyNumber}`
  }
  if (value.length <= 8) {
    return `${creditCardOnlyNumber.slice(0, 4)}.${creditCardOnlyNumber.slice(4, 8)}`
  }
  if (value.length <= 12) {
    return `${creditCardOnlyNumber.slice(0, 4)}.${creditCardOnlyNumber.slice(
      4,
      8
    )}.${creditCardOnlyNumber.slice(8, 12)}`
  } else {
    return `${creditCardOnlyNumber.slice(0, 4)}.${creditCardOnlyNumber.slice(
      4,
      8
    )}.${creditCardOnlyNumber.slice(8, 12)}.${creditCardOnlyNumber.slice(12, 16)}`
  }
}

export const cvvValidator = value => {
  if (value.length <= 3) {
    return value
  } else {
    return value.slice(0, value.length - 1)
  }
}

export const formatDate = (date, includeTime = true, fullMonth = false) => {
  if (!date) {
    return null
  }
  date = new Date(date)

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  let hours = date.getHours()
  let minutes = date.getMinutes()

  if (day.toString().length === 1) day = '0' + day

  if (month.toString().length === 1) month = '0' + month

  if (hours.toString().length === 1) hours = '0' + hours

  if (minutes.toString().length === 1) minutes = '0' + minutes

  let dateSerialized = `${day}/${month}/${year}`

  if (fullMonth) {
    const monthList = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ]
    dateSerialized = `${day} de ${monthList[month - 1]} de ${year}`
  }

  return dateSerialized + (includeTime ? ` ${hours}:${minutes}` : '')
}

export const base64 = bytes => {
  const byteCharacters = atob(bytes)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf' })
  return file
}

export const phone = value => {
  if (!value) {
    return value
  }

  const phoneOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (value.length <= 2) {
    return `(${phoneOnlyNumber.slice(0, 2)}`
  }
  if (value.length <= 6) {
    return `(${phoneOnlyNumber.slice(0, 2)}) ${phoneOnlyNumber.slice(2, 6)}`
  }
  if (value.length <= 10) {
    return `(${phoneOnlyNumber.slice(0, 2)}) ${phoneOnlyNumber.slice(2, 6)}-${phoneOnlyNumber.slice(
      6,
      11
    )}`
  } else {
    return `(${phoneOnlyNumber.slice(0, 2)}) ${phoneOnlyNumber.slice(2, 7)}-${phoneOnlyNumber.slice(
      7,
      11
    )}`
  }
}

export const cepMask = value => {
  if (!value) {
    return value
  }

  const cep = onlyNumber(value)

  if (cep.length <= 5) {
    return `${cep.slice(0, 5)}`
  } else {
    return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`
  }
}

const multiplica = (array1, array2) => {
  let arrayTotal = []
  for (let i = 0; i < array1.length; i++) {
    arrayTotal.push(array1[i] * array2[i])
  }
  return arrayTotal
}
const soma = array => {
  let total = 0
  for (let i = 0; i < array.length; i++) {
    total = total + array[i]
  }
  return total
}
const valorRepetido = value => {
  return value.split('').every(char => char === value[0])
}
export const validateCpf = value => {
  value = String(value)

  const chave1 = [10, 9, 8, 7, 6, 5, 4, 3, 2]
  const chave2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]

  let arrayCpf = []

  if (value.length === 14) {
    arrayCpf = value.split('.').join('').split('-')
  } else if (value.length === 11) {
    arrayCpf = [value.slice(0, 9), value.slice(9)]
  } else {
    return false
  }

  if (valorRepetido(arrayCpf.join(''))) return false

  const mod = value => (value * 10) % 11
  const verificar10ou11 = value => (value === 10 || value === 11 ? 0 : value)

  let resto1 = verificar10ou11(mod(soma(multiplica(arrayCpf[0], chave1))))
  let resto2 = verificar10ou11(mod(soma(multiplica(arrayCpf[0] + arrayCpf[1][0], chave2))))

  if (String(resto1) + String(resto2) === arrayCpf[1]) {
    return true
  } else {
    return false
  }
}
export const validateCnpj = value => {
  value = String(value)

  const chave1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const chave2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  let arrayCnpj = []

  if (value.length === 18) {
    arrayCnpj = value.split('.').join('').replace('/', '').split('-')
  } else if (value.length === 14) {
    arrayCnpj = [value.slice(0, 12), value.slice(12)]
  } else {
    return false
  }

  if (valorRepetido(arrayCnpj.join(''))) return false

  const mod = value => value % 11
  const menorQue2 = value => (value < 2 ? 0 : 11 - value)

  let resto1 = menorQue2(mod(soma(multiplica(arrayCnpj[0], chave1))))
  let resto2 = menorQue2(mod(soma(multiplica(arrayCnpj[0] + arrayCnpj[1][0], chave2))))

  if (String(resto1) + String(resto2) === arrayCnpj[1]) {
    return true
  } else {
    return false
  }
}

export const currency = (value, decimals = true) => {
  if (decimals) {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})$/, ',$1')
    value = value.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2')
  }

  let qtdLoop = (value.length - 3) / 3
  let count = 0
  while (qtdLoop > count) {
    count++
    value = value.replace(/(\d+)(\d{3}.*)/, '$1.$2')
  }
  value = value.replace(/^(0)(\d)/g, '$2')

  return value
}

//https://stackoverflow.com/questions/2692323/code-golf-friendly-number-abbreviator
export const abbrNum = (number, decPlaces) => {
  decPlaces = Math.pow(10, decPlaces)

  var abbrev = ['K', 'M', 'B', 'T']

  for (var i = abbrev.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3)
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces
      number += abbrev[i]
      break
    }
  }
  return number
}

export const getHour = dateTime => {
  return dateTime.split('T')[1].slice(0, 5)
}
