import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "../src/containers/Home";
import "./styles/index.scss";

function App() {
  return (
    <Router>
      <div className="row">
        <div className="column">
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
